import { Component, OnInit, Input, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ErrorMessageDirective } from '@app/core/errorMessage/error-message.directive';
import { Subject } from 'rxjs';
import { Routes } from '@angular/router';






@Component({
  selector: 'sa-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
 

  constructor(public modalRef: BsModalRef) { }

  
  ngOnInit() {
  
  }
  
  Ok(data:any): void {
    data.remove()
    this.modalRef.hide();
   
  }

  Cancel(): void {
    this.modalRef.hide();
  }


}
