import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { ApiService } from '@app/shared/api/api.service';
import { UserService } from '@app/shared/user/user.service';



@Injectable({
  providedIn: 'root'
})

export class AuthService {

  isLoggedIn = false;

  /* rediredt url*/
  redirectUrl: string;

  /*assign user value in currentUser */
  public currentUser: any;
  constructor(private api: ApiService, private router: Router, public user: UserService) {
    this.router.events.subscribe((e) => {
    });
  }

  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  getCurrentUser() {
    return this.currentUser;
  }
  
  private TOKEN_KEY = 'access-token';


  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem(this.TOKEN_KEY) || localStorage.getItem(this.TOKEN_KEY);
    return !!token;
  }

  /**
   * Login to server
   * @param {String} email The user email id.
   * @param {String} password The user password.
   * @return {Observable<any>} Observable that will be resolve on compile time.
   */
  public Login(email: string, password: string, rembember: boolean): Observable<any> {
    return new Observable((observer) => {
      // Clear Authorization token for API requests
      this.api.clearAuthorizationToken();
      this.api.http.all('users/login').post({ email: email, password: password }).subscribe(user => {
        this.setCurrentUser(user);
        this.isLoggedIn = true;
        var storage = rembember ? localStorage : sessionStorage;
        // Set Authorization token for API requests
        this.api.setAuthorizationToken(user.token);
        storage.setItem(this.TOKEN_KEY, JSON.stringify({
          userId: user.user.id,
          token: user.token,
          last_login: user.user.last_login
        }));
        observer.next(user.plain());
        observer.complete();

      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
    
  }


  public verifyEmail(data:any){
    return this.api.http.all('users/email/verify').post(data);
  }


  public autologin(): Observable<any> {
    return new Observable((observer) => {

      this.isLoggedIn = true;
      var data = sessionStorage.getItem(this.TOKEN_KEY) || localStorage.getItem(this.TOKEN_KEY);
      
      if (!data) {
        return false;
      }

      var authData = JSON.parse(data);
      this.api.setAuthorizationToken(authData.token);

      //     // Check if user has logged in before 24 hours then logout the user

      var last_login = moment(authData.last_login);
      var now = moment();
      var duration = moment.duration(now.diff(last_login));
      var hours = duration.days() * 24 + duration.hours();

      if (hours >= 24) {
        this.logOut();
        return false;
      } else {

        return this.user.Users.get(authData.userId).subscribe((user) => {
          this.currentUser = user;
          observer.next(user.plain());
          observer.complete()
        }, (error)=>{
          this.logOut();
        });
      }
    });

  }
  /* logout function*/

  public logOut() {
    this.isLoggedIn = false;
    this.api.clearAuthorizationToken();
    localStorage.removeItem(this.TOKEN_KEY)
    sessionStorage.removeItem(this.TOKEN_KEY)
  }

  getToken(){
  var tokenData = localStorage.getItem(this.TOKEN_KEY)|| sessionStorage.getItem(this.TOKEN_KEY);
  return JSON.parse(tokenData).token
  }
}
