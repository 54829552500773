import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@app/features/auth/auth.service';
import { HomeServiceService } from '@app/features/home/home-service.service';



@Component({
  selector: 'sa-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
  public navs: any
  constructor(private auth: AuthService, private router: Router, public homeserviceservice: HomeServiceService) {
  }

  ngOnInit() {
    this.homeserviceservice.get().subscribe((response) => {
      this.navs = response;
    });
  }

  logOut() {
    this.auth.logOut();
    this.router.navigate(['/home']);
  }

  employersRegister(userType: string) {
    this.router.navigate(['/user/register'], { queryParams: { type: userType } })
  }

  navigateList(cat:any){
    this.router.navigate(['/jobs'], {
      queryParams: {        
        sector: cat.id        
      }
    });
  }

}
