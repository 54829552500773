import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(public http: Restangular) { }

  public clearAuthorizationToken(): void {
    this.http.provider.setDefaultHeaders({});
  }
  
  public setAuthorizationToken(token: string): void {
    this.http.provider.setDefaultHeaders({Authorization: 'Token ' + token});
  }
  
 
}
