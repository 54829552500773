import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReferFriendService {

  constructor(
    public api: ApiService
  ) { }

  public ReferFriend = this.api.http.all('refer').all('jobs');

  addReferFriend(data:any){
    return this.ReferFriend.post(data)
  }

}
