import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { listRouitng } from './list-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { AnonymousApplicationComponent } from './anonymous-application/anonymous-application.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';

@NgModule({
  imports: [
    CommonModule,
    listRouitng,
    CKEditorModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    FormsModule  
  ],
  declarations: [ListComponent, AnonymousApplicationComponent, JobDetailComponent, ReferFriendComponent]
})
export class ListModule { }
