import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@app/shared/api/api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { HomeServiceService } from '../../home/home-service.service';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@env/environment';
import { BsModalService } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';
import { AnonymousApplicationComponent } from '../anonymous-application/anonymous-application.component';
import { ListService } from '../list.service';
import { ReferFriendComponent } from '../refer-friend/refer-friend.component';

@Component({
  selector: 'sa-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css'],
})
export class JobDetailComponent implements OnInit, OnDestroy {

  apiServerRootUrl = environment.apiServerRootUrl;
  navigationSubscription:any;
  categoryFilters: any[] = [];
  currentUser: any;
  rowDetails: any;
  appliedJob: any;
  jobAppliedResponse: any[] = [];
  wishListData: any;
  wishListReponse: any[] = [];
  
  constructor(    
    public listservice: ListService,
     private router: Router, 
     public api: ApiService,      
     public route: ActivatedRoute, 
     public authservice: AuthService, 
     public homeservice: HomeServiceService, 
     private notificationService: NotificationService,
     private modalService: BsModalService,
     private titleService: Title,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });    

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.currentUser = this.authservice.currentUser;    
    this.route.params.subscribe((response) => {      
      if(response.slug){
        this.getJob(response.slug);
      }     
    });
  }

  getJob(slug: any): void {    
    this.listservice.getDetailBySlug(slug).subscribe((response) => {
      this.rowDetails = response;
      this.titleService.setTitle(response.title);
      if (this.currentUser) {
        this.getAppliedJob(this.currentUser.id, this.rowDetails.id);
      }
      if (this.currentUser) {
        this.getWishlists(this.currentUser.id, this.rowDetails.id);
      }      
    });
  }

  appplyJob() {
    var data = {
      'user': this.currentUser.id,
      'job': this.rowDetails.id
    };
    this.listservice.applaySelectedJob(data).subscribe((response) => {
      this.appliedJob = response;
      this.notificationService.smallBox({
        title: "Your have successfully apply selected job",
        content: "<i class='fa fa-lock'></i> your password has chnage sucessfully",
        color: "#739e73",
        iconSmall: "fa fa-thumbs-up bounce animated",
        timeout: 4000
      });
    },
      errorResponse => {
        this.notificationService.smallBox({
          title: "Please try after some time",
          content: "<i class='fa fa-clock-o'></i> something is work",
          color: "#77021d",
          iconSmall: "fa fa-thumbs-down bounce animated",
          timeout: 6000
        });
      });
  }

  applyAnonymousJob(type:string, job:any){
    const initialState = { 
      job: job,
      type: type
    };
    this.modalService.show(AnonymousApplicationComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }

  getAppliedJob(userId: number, jobId: number) {
    if (this.currentUser) {
      this.listservice.getAllAppliedJobList(userId, jobId).subscribe((response) => {
        this.jobAppliedResponse = response;
      })
    }
  }

  wishList() {
    this.listservice.wishlists(this.currentUser.id, this.rowDetails.id).subscribe((response) => {
      this.wishListData = response;
    })
  }

  getWishlists(userId: number, jobId: number) {
    if (this.currentUser) {
      this.listservice.getWishlists(userId, jobId).subscribe((response) => {
        this.wishListReponse = response;
      });
    }
  }

  referFriend(job:any){    
    const initialState = { 
      job: false,
      share_url: window.location.href      
    };
    this.modalService.show(ReferFriendComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }  


}

