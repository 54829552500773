import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {
 public listUrl= this.api.http.all('jobs');
 public wishListUrl= this.api.http.all('jobs').all('wishlists');
 
  constructor(private api:ApiService) { }

  getCounties(){
    return this.listUrl.all('countries').getList();
  }

  get(){
   return  this.listUrl.getList();
  }

  getDetail(id:number){
    return this.listUrl.get(id)
  }

  getDetailBySlug(slug:string){
    return this.listUrl.get(slug)
  }

  getAllAppliedJobList(userId:number, jobId:number){
    return this.listUrl.all('applications').getList({'user':userId,'job':jobId})
  }

  applaySelectedJob(data){
   return this.listUrl.all('applications').post(data)
  }

  wishlists(userId:number, jobId:number){
    return this.wishListUrl.post({'user':userId,'job':jobId})
  }
  
  getWishlists(userId:number, jobId:number){
    return this.wishListUrl.getList({'user':userId,'job':jobId})
  }
}
