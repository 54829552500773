import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReferFriendService } from './refer-friend.service';
import { NotificationService } from '@app/core/services/notification.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'sa-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.css']
})
export class ReferFriendComponent implements OnInit {

  constructor(
    public referFriendService: ReferFriendService,
    private notificationService: NotificationService,
    public modalRef: BsModalRef,
  ) { }

  @Input() job: any;
  @Input() share_url: any;
  public referForm: FormGroup;

  ngOnInit() {
    this.referForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),      
      from_email: new FormControl('', [Validators.required, Validators.email]),      
      to_email: new FormControl('', [Validators.required, Validators.email]),      
      job: new FormControl(this.job.id||null)    
    });
  } 
  
  referFriend(){
    this.referFriendService.addReferFriend(this.referForm.value).subscribe(
      (response)=>{
        this.notificationService.smallBox({
          title: "Status",
          content: "<i class='fa fa-lock'></i> You have successfully shared the job",
          color: "#739e73",
          iconSmall: "fa fa-thumbs-up bounce animated",
          timeout: 4000
        });
        this.modalRef.hide();
      }, (error)=>{
        this.notificationService.smallBox({
          title: "Please try after some time",
          content: "<i class='fa fa-clock-o'></i> something is went wrong",
          color: "#77021d",
          iconSmall: "fa fa-thumbs-down bounce animated",
          timeout: 6000
        });
        this.modalRef.hide();
      }
    );
  }

  shareJob(share_url:any){
    var url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + share_url.replace('#', '%23');
    window.open(url, '_blank')
  }

  decline(): void {
    this.modalRef.hide();
  }

}
