import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionSettingService {

  constructor(public api: ApiService) { }

  public Subscriptions = this.api.http.all('subscriptions');

  getSubscriptions(filters:any){
    return this.Subscriptions.getList(filters);
  }

  addSubscription(subscription:any){
    return this.Subscriptions.post(subscription);
  }

  editSubscription(id:Number, subscription:any){
    return this.Subscriptions.all(id).patch(subscription)
  }

}
