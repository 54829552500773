import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { LayoutService } from '@app/core/services/layout.service';
import { environment } from '@env/environment';
import { AuthService } from '@app/features/auth/auth.service';

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {
  apiServerRootUrl = environment.apiServerRootUrl;
  public user: any;

  constructor(
    public us: UserService,
    public authService: AuthService,
    private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
  }

  toggleShortcut() {
    //this.layoutService.onShortcutToggle()
  }

}
