import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListService } from './list.service';
import { ApiService } from '@app/shared/api/api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { HomeServiceService } from '../home/home-service.service';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@env/environment';
import { BsModalService } from 'ngx-bootstrap';
import { AnonymousApplicationComponent } from './anonymous-application/anonymous-application.component';
import { Title } from '@angular/platform-browser';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';

@Component({
  selector: 'sa-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit, OnDestroy {
  public rows= new Array();
  public selectedHero: any;
  public rowDetails: any;
  public data: any;
  public currentUser: any;
  public filterData: any;
  public sectors: string;
  public category: any;
  public appliedJob: any;
  public jobAppliedResponse = new Array();
  public wishListReponse = new Array();
  public wishListData: any;
  public ischecked = false;
  public togle: boolean = false;
  public currentPage: any = 1;
  public countJobs: any[] = [];
  public pageSize: any = 10;
  public totalPages = 0;
  public last = false;
  public first = false;
  public jobId = 0;
  apiServerRootUrl = environment.apiServerRootUrl;
  navigationSubscription:any;
  categoryFilters: any[] = [];

  homeSearchForm = new FormGroup({
    search: new FormControl(''),
    sector: new FormControl(''),
    type: new FormControl(''),
  });

  constructor(
    public listservice: ListService,
     private router: Router, 
     public api: ApiService,      
     public route: ActivatedRoute, 
     public authservice: AuthService, 
     public homeservice: HomeServiceService, 
     private notificationService: NotificationService,
     private modalService: BsModalService,
     private titleService: Title,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {        
        this.ngOnInit();
      }
    });    

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.currentUser = this.authservice.currentUser;
    this.homeservice.get().subscribe((response) => {
      this.sectors = response;
    });
    this.route.queryParams.subscribe((response) => {
      if(response.jobId){
        this.jobId = response.jobId;
      }
      this.homeSearchForm = new FormGroup({
        search: new FormControl(response.search||''),
        sector: new FormControl(response.sector||''),
        type: new FormControl(response.type||''),
      });      
    });
    this.filter();
    this.categoryBySector();
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.filter();
  }

  categoryBySector() {
    this.api.http.withConfig((config) => {
      config.setResponseExtractor((response) => {
        this.data = response;
        return this.data;
      });
    }).all('categories').getList({ sector: this.homeSearchForm.value.sector }).subscribe(response => {
      this.category = response;         
    });
  }

  onSelect(data: any): void {
    this.listservice.getDetail(data).subscribe((response) => {
      this.rowDetails = response;
      this.titleService.setTitle(response.title);
      if (this.currentUser) {
        this.getAppliedJob(this.currentUser.id, this.rowDetails.id);
      }
      if (this.currentUser) {
        this.getWishlists(this.currentUser.id, this.rowDetails.id);
      }
      this.selectedHero = data;
    });
  }

  filter() {
    var commaSeparetedCategory = this.categoryFilters;
    var filters = this.homeSearchForm.value;
    filters['page'] = this.currentPage;
    filters['page_size'] = this.pageSize;
    if(this.categoryFilters.length>0){
      filters['category'] = commaSeparetedCategory.join(',');
    }else{
      filters['category'] = '';
    }
    this.api.http.withConfig((config) => {
      config.setResponseExtractor((response) => {
        this.rows = response.results;
        this.countJobs = [];
        this.totalPages = Math.ceil(response.count / this.pageSize);        
        var start=1;
        var end = this.totalPages;
        if(this.currentPage > 3){
          this.first = true;
          start = this.currentPage - 2;
        }

        if(this.currentPage <= 3){
          this.first = false;          
        }

        if(this.totalPages > 5 && this.currentPage<this.totalPages){
          this.last = true;
          end = this.currentPage + 2;
        }

        if(this.currentPage >= this.totalPages - 2){
          this.last = false;
          end = this.totalPages;
        }
        
        for (var i=start; i <= end; i++) {
          this.countJobs.push(i);
        }
        return this.rows
      });
    }).all('jobs').getList(filters).subscribe(response => {
      this.rows = response;
      if (response.length > 0 && this.jobId == 0) {
        this.onSelect(response[0].id);
      } else if(this.jobId !=0){
        this.onSelect(this.jobId);
      }
    });
  }

  catgoryFilter(categoryId, event) {    
    if(event.target.checked) {
      this.categoryFilters.push(categoryId)
    }else{
      this.categoryFilters.splice(this.categoryFilters.indexOf(categoryId), 1)
    }
    this.filter();
  }

  toggle() {
    this.togle = !this.togle;
  }

  appplyJob() {
    var data = {
      'user': this.currentUser.id, 
      'job': this.rowDetails.id
    };
    this.listservice.applaySelectedJob(data).subscribe((response) => {
      this.appliedJob = response;
      this.notificationService.smallBox({
        title: "Your have successfully apply selected job",
        content: "<i class='fa fa-lock'></i> your password has chnage sucessfully",
        color: "#739e73",
        iconSmall: "fa fa-thumbs-up bounce animated",
        timeout: 4000
      });
    },
      errorResponse => {
        this.notificationService.smallBox({
          title: "Please try after some time",
          content: "<i class='fa fa-clock-o'></i> something is work",
          color: "#77021d",
          iconSmall: "fa fa-thumbs-down bounce animated",
          timeout: 6000
        });
      });
  }

  applyAnonymousJob(type:string, job:any){
    const initialState = { 
      job: job,
      type: type
    };
    this.modalService.show(AnonymousApplicationComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }

  getAppliedJob(userId: number, jobId: number) {
    if (this.currentUser) {
      this.listservice.getAllAppliedJobList(userId, jobId).subscribe((response) => {
        this.jobAppliedResponse = response;
      })
    }
  }

  wishList() {
    this.listservice.wishlists(this.currentUser.id, this.rowDetails.id).subscribe((response) => {
      this.wishListData = response;
    })
  }

  getWishlists(userId: number, jobId: number) {
    if (this.currentUser) {
      this.listservice.getWishlists(userId, jobId).subscribe((response) => {
        this.wishListReponse = response;
      });
    }
  }

  referFriend(job:any){
    if(window.location.href.indexOf('#')>-1){
      var location = window.location.origin + '/#/jobs/' + job.slug;
    }else{
      var location = window.location.origin + '/jobs/' + job.slug;
    }
    const initialState = { 
      job: false,
      share_url: location      
    };
    this.modalService.show(ReferFriendComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }  

  jobDetail(slug){
    this.router.navigate(['/jobs', slug]);
  }

}

