import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { existingEmailValidator } from '@app/features/auth/directives/unique-email.directive';
import { UserService } from '@app/shared/user/user.service';
import { BsModalRef } from 'ngx-bootstrap';
import { HomeServiceService } from '@app/features/home/home-service.service';
import { ListService } from '../list.service';
import { CandidatesService } from '@app/features/candidates/candidates.service';
import { NotificationService } from '@app/core/services';
import { ApiService } from '@app/shared/api/api.service';

@Component({
  selector: 'sa-anonymous-application',
  templateUrl: './anonymous-application.component.html',
  styleUrls: ['./anonymous-application.component.css']
})
export class AnonymousApplicationComponent implements OnInit {

  @Input() job: any;  
  @Input() type: any;
  public sectors: any[] = [];
  public countries: any[] = [];  
  public registerForm: FormGroup;

  constructor(
    public api: ApiService,
    public userService: UserService, 
    public homeservice: HomeServiceService,
    public modalRef: BsModalRef,
    public jobService: ListService,
    public candidateService: CandidatesService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    if(this.type=='job'||this.type=='graduate'){
      this.homeservice.get().subscribe((response) => {
        this.sectors = response;
      });
  
      this.jobService.getCounties().subscribe((response) => {
        this.countries = response;
      });
    }    

    this.registerForm = new FormGroup({
      first_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      last_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required], [existingEmailValidator(this.userService)]),
      mobile_phone: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      terms_and_conditions: new FormControl(0),
      type: new FormControl('C'),
      sector: new FormControl(''),
      resume: new FormControl('', Validators.required),
      country: new FormControl(''),      
      job: new FormControl(this.job.id||null)    
    });
  }

  applyAnonymous(){
    const formData= new FormData();
    formData.append('first_name', this.registerForm.get('first_name').value);
    formData.append('last_name', this.registerForm.get('last_name').value);
    formData.append('email', this.registerForm.get('email').value);    
    formData.append('terms_and_conditions', this.registerForm.get('terms_and_conditions').value);
    formData.append('type', this.registerForm.get('type').value);
    formData.append('resume', this.registerForm.get('resume').value);
    if(this.type == 'quick'){
      formData.append('mobile_phone', this.registerForm.get('mobile_phone').value);
    }
    if(this.type=='job'|| this.type=='graduate'){
      formData.append('country', this.registerForm.get('country').value);
    }

    this.candidateService.registerForAnonymous(formData).subscribe((response)=>{
      this.api.setAuthorizationToken(response.token);
      var data = {
        'user': response.user.id
      };
      if(this.type == 'job' && this.job){
        data['job'] = this.job.id;
      }
      if(this.type == 'graduate'){
        data['sector'] = this.registerForm.value.sector;
      }
      this.jobService.applaySelectedJob(data).subscribe((response)=>{
        this.notificationService.smallBox({
          title: "Job Status",
          content: "<i class='fa fa-lock'></i> Your have successfully apply selected job",
          color: "#739e73",
          iconSmall: "fa fa-thumbs-up bounce animated",
          timeout: 4000
        });
        this.api.clearAuthorizationToken();
        this.modalRef.hide();
      }, (error)=>{
        this.notificationService.smallBox({
          title: "Please try after some time",
          content: "<i class='fa fa-clock-o'></i> something is went wrong",
          color: "#77021d",
          iconSmall: "fa fa-thumbs-down bounce animated",
          timeout: 6000
        });
        this.api.clearAuthorizationToken();
        this.modalRef.hide();
      });
    });
  }

  decline(): void {
    this.modalRef.hide();
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      this.registerForm.get('resume').setValue(event.target.files[0])
    }
  }

}
