import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public api:ApiService) {}
  public Users:any = this.api.http.all('users');
  public Employee:any = this.api.http.all('employers');
  public Candidates:any = this.api.http.all('candidates');

  public registerEmployee(user:any){
    return this.Employee.all('register').post(user, null, {Authorization:''});

  }

  public registerCandidates(user:any){
    return this.Candidates.all('register').post(user, null, {Authorization:''});

  }

  public getUserByEmail(email:string){
    return this.Users.getList({email:email});
  }

  public changePwd(userId:number, data:any){
    return this.Users.all(userId).all('password').all('change').patch(data)
  }

  }

