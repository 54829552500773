import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api/api.service';

@Injectable({
  providedIn: 'root'
})

export class HomeServiceService {
  private homeUrl:any= this.api.http.all('sectors');
  constructor(public api:ApiService) { }

 get(){
   return this.homeUrl.getList();
 }
  
}
