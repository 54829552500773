import { environment } from '../../../environments/environment';

export function RestangularConfigFactory (RestangularProvider, router){
  RestangularProvider.setBaseUrl(environment.apiServerUrl);

  RestangularProvider.addFullRequestInterceptor( (element, operation, path, url, headers, params) => { 
    var lod = document .getElementsByClassName("overlay")[0] .setAttribute("style", "display:block;"); 
    return { 
      params: Object.assign({}, params), 
      headers: headers, element: element 
    }; 
  }); 
  
  RestangularProvider.addResponseInterceptor( (data, operation, what, url, response) => { 
    var lod = document .getElementsByClassName("overlay")[0] .setAttribute("style", "display:none;"); 
    return data || {}; 
  }); 
  
  // ErrorInterceptor 
  RestangularProvider.addErrorInterceptor( (response, subject, responseHandler, {}) => {     
    var lod = document .getElementsByClassName("overlay")[0] .setAttribute("style", "display:none;");
    if (response.status === 404) { 
      router.navigate(["/errors/404"]); 
    } else if (response.status === 500) { 
      router.navigate(["/errors/500"]); 
    } 
  });
}
