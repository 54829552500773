import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { AnonymousApplicationComponent } from '@app/features/list/anonymous-application/anonymous-application.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionSettingService } from '@app/shared/site-content/subscription-setting.service';
import { NotificationService } from '@app/core/services/notification.service';
import { ReferFriendComponent } from '@app/features/list/refer-friend/refer-friend.component';

@Component({
  selector: 'sa-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    public subscriptionService: SubscriptionSettingService,
    private notificationService: NotificationService, 
  ) { }

  newsletterForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  public url: any = location.origin;

  ngOnInit() {
  }

  applyAnonymousJob(type:string, job:any){
    const initialState = { 
      job: job,
      type: type
    };
    this.modalService.show(AnonymousApplicationComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }

  referFriend(job:any){
    const initialState = { 
      job: false,
      share_url: this.url      
    };
    this.modalService.show(ReferFriendComponent, Object.assign({}, 
      { class: 'modal-md',initialState}));    
  }

  subscribe(){
    var data = {      
      'email': this.newsletterForm.value.email,
      'status': true,
      'type': 'N'
    };
    this.subscriptionService.addSubscription(data).subscribe(
      (response)=>{
        this.notificationService.smallBox({
          title: "Your information has been submitted",
          content: "<i class='fa fa-lock'></i> Your email has been subscribed to newletter.",
          color: "#739e73",
          iconSmall: "fa fa-thumbs-up bounce animated",
          timeout: 4000
        });
        this.newsletterForm.reset();
      },
      (error)=>{
        this.notificationService.smallBox({
          title: "Error",
          content: "<i class='fa fa-clock-o'></i> Something is went wrong.",
          color: "#77021d",
          iconSmall: "fa fa-thumbs-down bounce animated",
          timeout: 6000
        });
      }
    );
  }

}
