import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {
public condidateURl = this.api.http.all('admin/candidates');
public Candidate = this.api.http.all('candidates');
  constructor(private api:ApiService) { }

  get(){
    return this.condidateURl.getList();
  }

  getDetail(id){
    return this.condidateURl.get(id);
  }
  getAppliedJob(data){
    return this.condidateURl.all(data.id).get("jobs")
  }

  registerForAnonymous(data:any){
    return this.Candidate.all('register').all('application').post(data);
  }
}
