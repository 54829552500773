import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '@app/features/auth/auth.service';


@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanLoad {

	constructor(private auth: AuthService, private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<any> {
		
		if (next.data.anonymous) {
			if (this.auth.isAuthenticated()) {
				return this.auth.autologin().toPromise().then(user => {
					return true
				})
			}
			return true
		} else {
			if (this.auth.isAuthenticated()) {

				return this.auth.autologin().toPromise().then(user => {
					return true
				})

			} else {
				this.auth.redirectUrl = state.url;
				this.router.navigate([('/user/login')])
				return false;
			}
		}
	}

	canLoad(route: Route): boolean {
		let url: string = route.path;
		if (this.auth.isAuthenticated()) {
		   return true; 
		}
		else {
			this.auth.redirectUrl = url;
			this.router.navigate([('/user/login')])
			return false;
		}
				
	  }

}
