import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { JobDetailComponent } from './job-detail/job-detail.component';

export const listRoutes:Routes=[
  {
    path:'', component:ListComponent,
    canActivate:[AuthGuard],
    data:{anonymous:true},
  },
  {
    path:':slug', component:JobDetailComponent,
    canActivate:[AuthGuard],
    data:{anonymous:true},
  }
]

export const listRouitng:ModuleWithProviders = RouterModule.forChild(listRoutes);
