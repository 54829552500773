import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule} from '@angular/router';
import { Erro404Component } from './erro404/erro404.component';
import { Erro500Component } from './erro500/erro500.component';

const errorsRoutes = [
  {
    path:'404', component:Erro404Component
  },
  {
    path:'500', component:Erro500Component
  },
  
]
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(errorsRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: [Erro404Component, Erro500Component]
})
export class ErrorsModule { }
