import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";
import { RouterModule } from "@angular/router";
import { PageHeaderComponent } from "./page-header.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  declarations: [
    PageHeaderComponent,
  ],
  exports: [
    PageHeaderComponent,
    
  ]
})
export class PageHeaderModule{}
