import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { EmptyLayoutComponent } from "./shared/layout/app-layouts/empty-layout.component";
import { Erro404Component } from "./error/erro404/erro404.component";
import { AuthGuard } from "./core/guards/auth.guard";


const routes: Routes = [
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full'
      },

      {
        path: 'home',
        loadChildren: './features/home/home.module#HomeModule',
      },

      {
        path: 'errors',
        loadChildren: './error/errors.module#ErrorsModule',
      },

      {
        path: 'about_us',
        loadChildren: './features/about-us/about-us.module#AboutUsModule',
      },

      {
        path: 'term_of_use',
        loadChildren: './features/terms-of-use/terms-of-use.module#TermsOfUseModule',
      },

      {
        path: 'privacy_policy',
        loadChildren: './features/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
      },

      {
        path: 'license',
        loadChildren: './features/license/license.module#LicenseModule',
      },

      {
        path: 'security',
        loadChildren: './features/security/security.module#SecurityModule',
      },

      {
        path: 'fuschia/content/career_advice',
        loadChildren: './features/career-advice/career-advice.module#CareerAdviceModule',
      },

      {
        path: 'account/register/my_activity_basic',
        loadChildren: './features/my-activity/my-activity.module#MyActivityModule',
      },

      {
        path: 'fuschia/events',
        loadChildren: './features/events/events.module#EventsModule',
      },

      {
        path: 'fuschia/testimonial',
        loadChildren: './features/testimonial/testimonial.module#TestimonialModule',
      },

      { 
        path: 'jobs',  
        loadChildren: './features/list/list.module#ListModule' 
      },

      
    ],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'dashboard',
   
    component: MainLayoutComponent,
    children: [      
      { 
        path: 'jobs', canLoad: [AuthGuard], 
        loadChildren: './features/jobs/jobs.module#JobsModule' 
      },
      { 
        path: 'profile', canLoad: [AuthGuard], 
        loadChildren: './features/profile/profile.module#ProfileModule' 
      },
      { 
        path: 'jobs/wishlist', canLoad: [AuthGuard], 
        loadChildren: './features/wishLists/wishlists.module#WishlistsModule' 
      },

      
    ]
  },

  {
    path: "user",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule"
  },
  
  {
    path: '**', component: Erro404Component
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
